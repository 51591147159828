/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import Header from "./header"

import "../styles/typography.css"
import "../styles/tailwind.css"

const Layout = ({ children }) => {
  return (
    <div className="relative w-full overflow-hidden">
      {/* <Header /> */}
      <main>
        {children}
      </main>

      {/* <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer> */}
    </div>
  )
}

export default Layout
